import Link from "next/link";

const Custom404 = () => {
    return (
        <div className="flex mid-column-content mx-auto pt-9 pb-16 md:py-28">
            <div className="flex flex-col items-center mx-auto">
                <h1 className="hero-header text-center font-body text-xl">
                    😊 Oopsy Daisy
                </h1>
                <p className="font-semibold my-4 mx-8 text-center font-body text-xl">
                    404 - Page Not Found
                </p>
                <Link href="/">
                    <button
                        className="text-center flex border-2 border-black hover:bg-black hover:text-white font-medium font-button px-8 py-2 text-sm mx-auto md:mx-4 mt-8">
                        BACK TO HOMEPAGE
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default Custom404;
